import React, { Component } from 'react';
import { Grid } from 'react-mdl';


class About extends Component {
  render() {
    return(
      <div style={{width: '100%', margin: 'auto'}}>
        <Grid className="backgroundpage">
          <h1>Welcome</h1>
        </Grid>
        
      </div>
    )
  }
}

export default About;
