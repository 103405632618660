import React from 'react';
import {Routes, Route } from 'react-router-dom';
import LandingPage from './landingpage';
import AboutMe from './aboutme';
import Projects from './projects';
import Resume from './resume';


const Main = () => (
  <Routes>
    <Route path="/" element={<LandingPage/>} />
    <Route path="/aboutme" element={<AboutMe/>} />
    <Route path="/projects" element={Projects} />
    <Route path="/resume" element={<Resume/>} />
  </Routes>
)

export default Main;
