import React, { Component } from 'react';
import image1 from '../image/sandrine.jpeg';

class Landing extends Component {
  render() {
    return(
      <div >
       {/*<Grid className="landing-grid">*/}
       <div className="max-w-3xl mx-auto py-16">
          <div className="flex flex-col items-center space-y-8">
         
            <img
              src={image1}
              alt="avatar"
              className="w-64 h-64 rounded-full object-cover"
              />

            <div className='banner-text p-4'>
            <div className="text-center">
              <h1 className="text-4xl font-bold text-gray-800">Sandrine Gamman</h1>
              <p className="text-xl text-gray-600 font-medium mt-2">Cloud Engineer</p>
            </div>
             
            <hr/>
        
        <p className='p-4'> HTML/CSS | JavaScript | TypeScript | React | Azure | Azure Devops | Power BI | Terraform | AZ 900 | Python | Docker | Kubernetes | Java  </p>

        <div className='social-links flex justify-center gap-6 mt-4'>

          {/* LinkedIn */}
      
          <a className='Lin' href="https://www.linkedin.com/in/sandrine-gamman-158bb4a5/" rel="noopener noreferrer" target="_blank">
            <i className="fa fa-linkedin-square" aria-hidden="true" />
          </a>

          {/* Github */}
          <a className='gt' href="https://github.com/FrueGamman" rel="noopener noreferrer" target="_blank">
            <i className="fa fa-github-square" aria-hidden="true" />
          </a>

          {/* Instagram*/}
          <a className='Inst' href="https://www.instagram.com/sacy14/" rel="noopener noreferrer" target="_blank">
            <i className="fa fa-instagram" aria-hidden="true" />
          </a>

          {/* Youtube */}
          <a className='yt ' href="https://www.youtube.com/channel/UCdE80cG7z4A25kXiF6zwOoA" rel="noopener noreferrer" target="_blank">
            <i className="fa fa-youtube-square" aria-hidden="true" />
          </a>

        </div>
            </div>

          
        {/*</Grid>*/}
        </div>
      </div>
      </div>
    )
  }
}

export default Landing;
