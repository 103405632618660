import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Education from './education';
import Experience from './experience';
import Skills from './skills';
import image1 from '../image/sandrine.jpeg';
import Certificate from './certificate';



class Resume extends Component {
  render() {
    return(
      <div >
        <Grid className="backgroundpage">
          <Cell className="resume-left-col" col={5} shadow={5}>
            <div style={{textAlign: 'center'}}>
              <img
                src={image1}
                alt="avatar"
                style={{height: '300px'}}
                 />
            </div>

            <h2 style={{paddingTop: '1em'}}>Sandrine Cyuzuzo Gamman</h2>
            <h4 style={{color: 'white'}}>Cloud Associate</h4>
            <hr style={{borderTop: '3px solid white', width: '50%'}}/>
            <p>My experience includes utilizing various tools such as Microsoft Azure, Terraform, Power BI, Github, and Azure DevOps for pipelines and deployment.

In addition to my practical experience, I have knowledge in various programming languages including C, JavaScript, React JS, Typescript, Python, and Shell script. My interests also lie in Cloud Native solutions and infrastructure as code. In my Bachelor's thesis, I assisted Gassco in developing a solution for transporting, analyzing, and presenting data from gas pipelines at the Norwegian continental shelf using Microsoft Azure and Power BI.

Furthermore, I am part of Capgemini's Ignite program, an extensive initiative that focuses on building market-leading knowledge and competencies.</p>
            <hr style={{borderTop: '3px solid white', width: '50%'}}/>
            <h5>Address</h5>
            <p>I hacked my way into the world, just e-mail me</p>
            <h5>Phone</h5>
            <p>+4700000000</p>
            <h5>Email</h5>
            <p>sandrw14@hotmail.com</p>
            <h5>Web</h5>
            <p>fruegamman.dev</p>
            <hr style={{borderTop: '3px solid white', width: '50%'}}/>
          </Cell>
          <Cell className="resume-right-col" col={7} shadow={5}>
            <h2>Education</h2>


            <Education
              startYear={2018}
              endYear={2022}
              schoolName="Agder University"
              schoolDescription="Agder University's Software Engineering program is comprehensive, providing students with a strong foundation in programming languages, object-oriented programming, project management, and software testing. The program emphasizes hands-on learning and prepares graduates for successful careers in the software development industry."
               />
            <Education
              startYear={2010}
              endYear={2013}
              schoolName="Skien videregående skole"
              schoolDescription="In the field of research line provides special study competence with program subjects within the sciences. This is the study offer for you who like and are motivated to work with science and mathematics and who want a little extra with the school."
               />
              <hr style={{borderTop: '3px solid #e22947'}} />

              <h2>Experience</h2>
            
              <Experience
              startYear={2022}
              
              jobName="Capgemini"
              jobDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
              />
            <Experience
              startYear={2021}
              endYear={2021}
              jobName="SYSCO AS"
              jobDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
              />
              
              <Experience
                startYear={2019}
                endYear={2019}
                jobName="Windmill AS"
                jobDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                />
              <Experience
                startYear={2019}
                endYear={2019}
                jobName="Kristiansand kommune"
                jobDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                />

              <Experience
                startYear={2019}
                endYear={2019}
                jobName="Skien Kommune"
                jobDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                />

              <Experience
                startYear={2019}
                endYear={2019}
                jobName="Porsgrunn Kommune"
                jobDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                />
              <hr style={{borderTop: '3px solid #e22947'}} />

              <h2>Certificates</h2>

              <Certificate 
              Certificate="AZ-900: Microsoft Azure Fundamentals"
              />
              <Certificate 
              Certificate="HashiCorp Certified: Terraform Associate"
              />
              <Certificate 
              Certificate="LFD459:Kubernetes for App Developers"
              />
              <h2>Skills</h2>
              <Skills
                skill="Javascript"
                progress={100}
                />
              <Skills
                skill="HTML/CSS"
                progress={80}
                  />
              <Skills
                skill="NodeJS"
                progress={50}
                    />
              <Skills
                skill="React"
                progress={25}
                   />
              <Skills
                skill="Microsoft Azure"
                progress={25}
                   />
              <Skills
                skill="Terraform"
                progress={25}
                   />


          </Cell>
        </Grid>
      </div>
    )
  }
}

export default Resume;
