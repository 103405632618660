import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';

class Certificate extends Component {
    
      render() {
        return(
          <Grid>
            <Cell col={12}>
              <div style={{display: 'flex'}}>{this.props.Certificate} </div>
            </Cell>
          </Grid>
        )
      }
    }
    


export default Certificate;